<template>
  <section class="justify-end px-0 py-3 font-EffraR">
    <div class="flex items-center gap-x-3">
      <div
        id="chevLeft"
        v-if="hasPrev()"
        @click.prevent="changePage(prevPage)"
        class="cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            d="M20 24l-8-8 8-8"
          />
        </svg>
      </div>
      <div
        v-if="hasFirst()"
        @click.prevent="changePage(1)"
        class="cursor-pointer bg-white w-8 h-8 rounded flex justify-center items-center"
      >
        <span class="font-EffraR text-2xl leading-none">
          1
        </span>
      </div>
      <div
        v-if="hasFirst()"
        class="font-EffraR text-2xl leading-none cursor-pointer bg-white w-8 h-8 rounded flex justify-center items-center"
      >
        ...
      </div>

      <div
        v-for="(page, i) in pages"
        :key="i"
        @click.prevent="changePage(page)"
        class="cursor-pointer bg-white w-8 h-8 rounded flex justify-center items-center"
        :class="{
          'bg-rosDok-lighter text-rosDok-dark': current == page
        }"
      >
        <span class="font-EffraR text-2xl leading-none">{{ page }}</span>
      </div>
      <div
        v-if="totalPages > 5 && current < 3"
        class=" bg-white w-8 h-8 rounded flex justify-center items-center"
      >
        ...
      </div>
      <div
        id="chevRight"
        v-if="hasNext()"
        @click.prevent="changePage(nextPage)"
        class="cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            d="M12 24l8-8-8-8"
          />
        </svg>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
export default {
  props: {
    current: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 9
    },
    pageRange: {
      type: Number,
      default: 2
    },
    textBeforeInput: {
      type: String,
      default: "Aller à la page"
    },
    textAfterInput: {
      type: String,
      default: "Aller"
    }
  },
  data() {
    return {
      input: ""
    };
  },
  methods: {
    hasFirst() {
      return this.rangeStart !== 1;
    },
    hasLast() {
      return this.rangeEnd < this.totalPages;
    },
    hasPrev() {
      return this.current > 1;
    },
    hasNext() {
      return this.current < this.totalPages;
    },
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.$emit("page-changed", page);
      }
    }
  },
  computed: {
    pages() {
      var pages = [];
      for (var i = this.rangeStart; i <= this.rangeEnd; i++) {
        pages.push(i);
      }
      return pages;
    },
    rangeStart() {
      var start = this.current - this.pageRange;
      return start > 0 ? start : 1;
    },
    rangeEnd() {
      var end = this.current + this.pageRange;
      return end < this.totalPages ? end : this.totalPages;
    },
    totalPages() {
      return Math.ceil(this.total / this.perPage);
    },
    nextPage() {
      return this.current + 1;
    },
    prevPage() {
      return this.current - 1;
    }
  }
};
</script>
